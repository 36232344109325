<template>
  <div class="main">
    <div class="searchBar d-flex my-3">
      <div class="create">
        <el-button color="#ffd964" @click="addClass()" type="warning">我要開課</el-button>
        <el-button type="danger" circle @click="deleteClass()"><i class="fa-solid fa-trash-can"></i></el-button>
      </div>
    </div>
    <el-table class="multipleTable" ref="multipleTable" @selection-change="handleSelectionChange" :data="displayData" :default-sort="{ prop: 'classID', order: 'descending' }">
      <el-table-column type="selection" :selectable="checkbox"></el-table-column>
      <el-table-column sortable label="課程ID" prop="classID" />
      <el-table-column sortable label="課程名稱" prop="className" />
      <el-table-column sortable label="科目類別" prop="instrumentType" v-if="!$store.getters.mobileSize" />
      <el-table-column sortable label="科目名稱" prop="instrumentName" v-if="!$store.getters.mobileSize" />
      <el-table-column sortable label="教學類別" prop="teachingType" v-if="!$store.getters.mobileSize" />
      <el-table-column prop="classStatus" label="課程狀態" sortable>
        <template #default="scope">
          <el-tag :type="scope.row.classStatus === '公開' ? 'success' : 'danger'" disable-transitions>{{ scope.row.classStatus }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column sortable label="建立時間" prop="createdDateTime" />
      <el-table-column label="修改課程資料" prop="manageClass" align="left" width="300">
        <template #header>
          <el-input v-model="search" placeholder="搜尋關鍵字" />
        </template>
        <template #default="scope">
          <el-button size="small" @click="handleEdit(scope.row)">修改課程資料</el-button>
          <el-button size="small" type="primary" @click="handleCopy(scope.row)">建立副本</el-button>
          <el-button type="warning" size="small" @click="preview(scope.row)">預覽課程</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="addClass">
      <el-drawer title="老師開課" :show-close="false" :direction="direction" size="100%" modal-class="drawer_tabs" v-model="isAddClassShow" v-if="$store.getters.mobileSize">
        <AddClass :key="componentKey" @close="isAddClassShow = false" @rerender="rerenderPage" />
      </el-drawer>
      <el-dialog title="老師開課" :show-close="false" width="80%" fullscreen="true" v-model="isAddClassShow" v-else>
        <AddClass :key="componentKey" @close="isAddClassShow = false" @rerender="rerenderPage" />
      </el-dialog>
    </div>
    <div class="pagination">
      <el-pagination layout="prev, pager, next" :total="pageTotal" @current-change="handleCurrentChange" :page-size="pageSize" />
    </div>
  </div>
</template>

<script>
import AddClass from "@/components/ChargeMeTribe/TeacherCenter/TeacherClass/AddClass.vue";
export default {
  name: "TeacherClass",
  components: {
    AddClass,
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      pageTotal: 0,
      sourceData: {},
      sourceTableData: [],
      tableData: [],
      searchTableData: [],
      multipleSelection: [],
      search: "",
      isSearchPopoverShow: false,
      isAddClassShow: false,
      instrumentOptions: [],
      componentKey: 0,
    };
  },
  async mounted() {
    await this.initData();
  },
  provide() {
    return {
      source: () => this.sourceData,
    };
  },
  computed: {
    displayData() {
      let displayData = this.searchData(this.searchTableData);
      this.changePageTotal(displayData);
      return displayData.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
    },
  },
  methods: {
    changePageTotal(displayData) {
      this.pageTotal = displayData.length;
    },
    preview(row) {
      var route = this.$router.resolve({
        path: `/Class/${row.classID}`,
      });
      window.open(route.href, "_blank");
    },
    addClass() {
      this.resetTeacherClassForm();
      if (this.sourceTableData.length != 0) this.$store.state.classForm.teacherId = this.sourceTableData[0].teacherId;
      this.isAddClassShow = true;
      this.componentKey += 1;
    },
    rerenderPage() {
      this.tableData = [];
      this.initData();
    },
    initData() {
      const vm = this;
      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/Courses/current`)
        .then(res => {
          if (res.data.courses.length != 0) {
            vm.sourceTableData = res.data.courses;
            res.data.courses.forEach(x => {
              vm.tableData.push({
                classID: x.id,
                className: x.name,
                instrumentType: x.instrumentType === "WesternMusic" ? "西樂" : x.instrumentType === "ChineseMusic" ? "中樂" : "其他",
                instrumentName: x.instrumentName,
                teachingType: x.teachingTypes.length == 2 ? "遠端/實體" : x.teachingTypes[0] == "Online" ? "遠端" : "實體",
                classStatus: x.status == "Published" ? "公開" : "非公開",
                createdDateTime: vm.changeCreateTime(x.createdDateTime),
              });
            });
          }
          vm.tableData.sort(function (a, b) {
            return b.classID - a.classID;
          });
          vm.searchTableData = vm.tableData;
        })
        .catch(err => {
          console.log(err);
        });
    },
    handleCurrentChange(val) {
      this.page = val;
    },
    searchData(table) {
      return table.filter(data => !this.search || data.className.toLowerCase().includes(this.search.toLowerCase()) || data.classID.toString().includes(this.search.toLowerCase()) || data.instrumentType.toLowerCase().includes(this.search.toLowerCase()) || data.teachingType.toLowerCase().includes(this.search.toLowerCase()) || data.instrumentName.toLowerCase().includes(this.search.toLowerCase()) || data.classStatus.toLowerCase().includes(this.search.toLowerCase()) || data.createdDateTime.toLowerCase().includes(this.search.toLowerCase()));
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    deleteClass() {
      const vm = this;
      if (vm.multipleSelection.length == 0) return;
      let data = [];
      vm.multipleSelection.forEach(x => {
        data.push(x.classID);
      });
      vm.fetchAPI("delete", `${process.env.VUE_APP_URL_API}/Courses/batch`, data)
        .then(() => {
          vm.notify("success", "課程刪除成功！");
        })
        .catch(err => {
          console.log(err);
          vm.notify("error", "課程刪除失敗！");
        })
        .finally(() => {
          vm.rerenderPage();
        });
    },
    handleEdit(row) {
      const vm = this;
      vm.sourceData = vm.sourceTableData.find(x => x.id == Number(row.classID));
      vm.$store.state.classForm.courseId = vm.sourceData.id;
      vm.$store.state.classForm.teacherId = vm.sourceData.teacherId;
      vm.$store.state.classForm.isAddNewClass = false;
      vm.isAddClassShow = true;
      vm.componentKey += 1;
    },
    handleCopy(row) {
      const vm = this;
      vm.sourceData = vm.sourceTableData.find(x => x.id == Number(row.classID));
      vm.sourceData.name = `副本 - ${vm.sourceData.name}`;
      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/Courses`, vm.sourceData)
        .then(() => {
          vm.notify("success", "課程建立副本成功！");
          this.resetTeacherClassForm();
          vm.$emit("rerender");
        })
        .catch(err => {
          console.log(err);
          vm.notify("error", "課程建立副本失敗！");
        })
        .finally(() => {
          vm.rerenderPage();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  display: flex;
  flex-grow: 1;
  padding: 0 1.5rem;
  flex-direction: column;
  .searchBar {
    justify-content: space-between;
  }
  .search_button {
    position: relative;
    z-index: 100;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 3rem;
    .el-pagination {
      align-items: flex-end;
    }
  }
}
:deep .el-table {
  flex-grow: 1;
  height: 70vh;
  overflow-y: auto;
}
</style>
